import "./App.css";
import Template from "./components/Template";
import Header from "./components/Header";
import Main from "./components/Main";
import ServiceIntroduction from "./components/ServiceIntroduction";
import EnterpriseIntroduction from "./components/EnterpriseIntroduction";
import ServiceMainContents from "./components/ServiceMainContents";
import ServiceContentsSummary from "./components/ServiceContentsSummary";
import ServiceInquiry from "./components/ServiceInquiry";
import Footer from "./components/Footer";

function App() {
  return (
    <Template>
      <Header></Header>
      <Main></Main>
      <ServiceIntroduction></ServiceIntroduction>
      <EnterpriseIntroduction></EnterpriseIntroduction>
      <ServiceMainContents></ServiceMainContents>
      <ServiceContentsSummary></ServiceContentsSummary>
      {/* <ServiceInquiry></ServiceInquiry> */}
      <Footer></Footer>
    </Template>
  );
}

export default App;
