import React from "react";
import { ReactComponent as CheckMarkImage } from "../assets/images/check_mark.svg";
import { ReactComponent as LogoImage } from "../assets/images/Logo_icon.svg";
import { ReactComponent as Checkbox } from "../assets/images/check_box.svg";

import styled, { css } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 704px;
  background: #f8f8f8;

  @media only screen and (max-width: 600px) {
    height: 1000px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    height: 1300px;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    margin-top: 30px;
  }
`;

const InfoContentsWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    display: flex;

    flex-direction: column;
    align-items: center;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

const ContentsText = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #010101;
  margin-top: 40px;
  margin-bottom: 40px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 31px;
    width: 100%;
    text-align: center;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 36px;
    line-height: 49px;
    width: 100%;
    text-align: center;
  }
`;

const ContentsList = styled.ul`
  width: 100%;

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin-top: 30px;
  }
`;

const ContentsListItem = styled.li`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #333333;

  display: flex;
  align-items: center;

  ${(props) =>
    props.second &&
    css`
      padding: 20px 0;
    `}

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const CompanyLogo = styled(LogoImage)`
  @media only screen and (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

const CheckMark = styled(CheckMarkImage)`
  margin-right: 26px;

  @media only screen and (max-width: 600px) {
    width: 14px;
    width: 14px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    &:not(:nth-child(4)) {
      margin-bottom: 40px;
    }
  }
`;

const SubmitButton = styled.button`
  width: 166px;
  height: 52px;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  background-color: #55c58b;
  cursor: pointer;
  font-size: 16px;

  @media only screen and (max-width: 600px) {
    width: 116px;
    height: 36px;
    font-size: 12px;
  }
`;

const SubmitButtonWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    margin: 0 auto;
  }
`;

const Label = styled.label`
  &::after {
    content: "*";
  }
  margin-bottom: 12px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 6px;
  width: 410px;
  height: 44px;
  padding: 14px 18px;
  outline: none;

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 12px;
    line-height: 17px;
  }
`;

const FormWrapper = styled.form`
  margin-left: 20px;
  margin-right: 40px;

  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TextArea = styled.textarea`
  width: 410px;
  height: 108px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 14px 18px;
  resize: none;
  outline: none;

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 12px;
    line-height: 17px;
  }
`;

const TermsTextWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

const TermsText = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
  vertical-align: middle;
  margin-left: 4px;

  @media only screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 14px;
  }
`;

const ServiceInquiry = () => {
  return (
    <Wrapper id="inquiry">
      <ContentsWrapper>
        <InfoWrapper>
          <InfoContentsWrapper>
            <CompanyLogo></CompanyLogo>

            <ContentsText>
              궁금한 것이 있다면 <br />
              언제든지 크로스팀에게 <br />
              문의해주세요.
            </ContentsText>
          </InfoContentsWrapper>
          <ContentsList>
            <ContentsListItem>
              <CheckMark></CheckMark>
              프로젝트의 생산성을 높이는 방법을 알려드려요.
            </ContentsListItem>
            <ContentsListItem second>
              <CheckMark></CheckMark>
              자세한 요금 정보를 제공해드려요.
            </ContentsListItem>
            <ContentsListItem>
              <CheckMark></CheckMark>
              사용 사례를 알려드려요.
            </ContentsListItem>
          </ContentsList>
        </InfoWrapper>

        <FormWrapper>
          <InputWrapper>
            <Label htmlFor="name">이름 </Label>
            <Input type="text" id="name" placeholder="이름을 입력하세요." />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="number">연락처 </Label>
            <Input type="tel" placeholder="010-0000-0000" id="number" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="email">이메일 </Label>
            <Input
              type="email"
              id="email"
              placeholder="support@crossteam.co.kr"
            />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="contents">문의 내용 </Label>
            <TextArea id="contents" placeholder="문의 내용을 입력해주세요." />
          </InputWrapper>

          <TermsTextWrapper>
            <Checkbox></Checkbox>
            <TermsText>개인정보 수집 및 활용동의에 동의합니다.</TermsText>
            <TermsText> [자세히]</TermsText>
          </TermsTextWrapper>

          <SubmitButtonWrapper>
            <SubmitButton>서비스 문의하기</SubmitButton>
          </SubmitButtonWrapper>
        </FormWrapper>
      </ContentsWrapper>
    </Wrapper>
  );
};

export default ServiceInquiry;
