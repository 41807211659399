import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #55c58b;

  @media only screen and (max-width: 900px) {
    height: 298px;
  }
`;

const Contents = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const ContentsTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin-bottom: 28px;
  }
`;

const ContentsTitle = styled.span`
  color: #fff;
  font-size: 40px;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }
`;

const ContentsParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  line-height: 38px;
  color: #fff;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ContentsParagraph = styled.p`
  text-align: center;
`;

const ServiceIntroduction = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="introduction">
      <Contents>
        <ContentsTitleWrapper>
          <ContentsTitle>SAVE TIME,&nbsp;</ContentsTitle>
          <ContentsTitle>SAVE COST</ContentsTitle>
        </ContentsTitleWrapper>

        <ContentsParagraphWrapper>
          <ContentsParagraph>
            {t("introduction-section.content1")}
          </ContentsParagraph>
          <ContentsParagraph>
            {t("introduction-section.content2")}
          </ContentsParagraph>
          <ContentsParagraph>
            {t("introduction-section.content3")}
          </ContentsParagraph>
          <ContentsParagraph>
            {t("introduction-section.content4")}
          </ContentsParagraph>
        </ContentsParagraphWrapper>
      </Contents>
    </Wrapper>
  );
};

export default ServiceIntroduction;
