import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import document from "../assets/images/document.svg";
import folder from "../assets/images/folder.svg";
import dashboard from "../assets/images/dashboard.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 880px;
  background-color: #fff;

  @media only screen and (max-width: 600px) {
    height: 1272px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    height: 1767px;
  }
`;

const Contents = styled.section`
  max-width: 1200px;
  height: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ContentsParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  /* @media only screen and (max-width: 900px) {
    margin-bottom: 94px;
  } */

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin-bottom: 0px;
  }
`;

const ContentsParagraphTextWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

const ContentsParagraphText = styled.p`
  color: #010101;
  font-size: 40px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  display: inline-block;

  @media only screen and (max-width: 600px) {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 26px;
  color: #1a202c;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const Image = styled.img`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media only screen and (max-width: 600px) {
    width: 84px;
    height: 100px;
  }
`;

const ImageParagraph = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #4a5568;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

const ImageContainer = styled.div`
  padding: 60px 0;
  width: 350px;
  height: 442px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: #fefefe;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.03);

  &:nth-child(2) {
    margin: 0 18px;
  }

  @media only screen and (max-width: 600px) {
    width: 246px;
    height: 300px;
    padding: 30px 0;
    margin: 28px 0;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin: 28px 0;
  }
`;

const ServiceContentsSummary = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Contents>
        <ContentsParagraphWrapper>
          <ContentsParagraphTextWrapper>
            <ContentsParagraphText>
              {t("contents-section.title1")}&nbsp;
            </ContentsParagraphText>
            <ContentsParagraphText>
              {t("contents-section.title2")}
            </ContentsParagraphText>
          </ContentsParagraphTextWrapper>

          <ContentsParagraphTextWrapper>
            <ContentsParagraphText>
              {t("contents-section.title3")}&nbsp;
            </ContentsParagraphText>
            <ContentsParagraphText>
              {t("contents-section.title4")}
            </ContentsParagraphText>
          </ContentsParagraphTextWrapper>
        </ContentsParagraphWrapper>

        <ImagesWrapper>
          <ImageContainer>
            <Image src={document}></Image>

            <div>
              <ImageTitle>{t("contents-section.item-1.title")}</ImageTitle>
              <ImageParagraph>
                {t("contents-section.item-1.content1")}
                <br />
                {t("contents-section.item-1.content2")}
                <br />
                {t("contents-section.item-1.content3")}
              </ImageParagraph>
            </div>
          </ImageContainer>

          <ImageContainer>
            <Image src={dashboard}></Image>
            <div>
              <ImageTitle>{t("contents-section.item-2.title")}</ImageTitle>
              <ImageParagraph>
                {t("contents-section.item-2.content1")}
                <br />
                {t("contents-section.item-2.content2")}
              </ImageParagraph>
            </div>
          </ImageContainer>

          <ImageContainer>
            <Image src={folder}></Image>

            <div>
              <ImageTitle>{t("contents-section.item-3.title")}</ImageTitle>
              <ImageParagraph>
                {t("contents-section.item-3.content1")} <br />
                {t("contents-section.item-3.content2")} <br />
                {t("contents-section.item-3.content3")}
              </ImageParagraph>
            </div>
          </ImageContainer>
        </ImagesWrapper>
      </Contents>
    </Wrapper>
  );
};

export default ServiceContentsSummary;
