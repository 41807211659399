import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import albumLarge from "../assets/images/album_large.png";
import albumMedium from "../assets/images/album_medium.png";
import albumSmall from "../assets/images/album_small.png";

import documentLarge from "../assets/images/document_large.png";
import documentMedium from "../assets/images/document_medium.png";
import documentSmall from "../assets/images/document_small.png";

import commentLarge from "../assets/images/comment_large.png";
import commentMedium from "../assets/images/comment_medium.png";
import commentSmall from "../assets/images/comment_small.png";

import inspectionLarge from "../assets/images/inspection_large.png";
import inspectionMedium from "../assets/images/inspection_medium.png";
import inspectionSmall from "../assets/images/inspection_small.png";

import driveLarge from "../assets/images/drive_large.png";
import driveMedium from "../assets/images/drive_medium.png";
import driveSmall from "../assets/images/drive_small.png";

import materialLarge from "../assets/images/material_large.png";
import materialMedium from "../assets/images/material_medium.png";
import materialSmall from "../assets/images/material_small.png";

import punchLarge from "../assets/images/punch_large.png";
import punchMedium from "../assets/images/punch_medium.png";
import punchSmall from "../assets/images/punch_small.png";

const Wrapper = styled.div`
  height: 666px;
  width: 100%;
  background: #f8f8f8;

  @media only screen and (max-width: 600px) {
    height: 650px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    height: 804px;
  }
`;

const MainContents = styled.section`
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

const SliderWrapper = styled(Slider)`
  height: 100%;
  padding-bottom: 10px;

  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-track .slick-slide {
    > div {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: 0px;
    padding-bottom: 40px;

    > li {
      margin: 0 3px;
    }

    > li.slick-active button:before {
      color: #55c58b;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 100% !important;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const SectionTitle = styled.div`
  font-size: 40px;
  line-height: 56px;
  color: #1a202c;
  font-weight: bold;
  margin-bottom: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    margin-top: 24px;

    margin-bottom: 24px;
  }
`;

const SectionSummary = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #4a5568;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

const Image = styled.img`
  max-width: 662px;
  max-height: 454px;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media only screen and (max-width: 600px) {
    max-width: 460px;
    max-height: 280px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    max-width: 460px;
    max-height: 340px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
`;

const ServiceMainContents = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    autoplay: true,
  };

  return (
    <Wrapper id="menu">
      <MainContents>
        <SliderWrapper {...settings}>
          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.photo.title1")} <br />
                {t("menu-section.photo.title2")}
                <br /> {t("menu-section.photo.title3")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.photo.content1")}
                <br />
                {t("menu-section.photo.content2")} <br />
                {t("menu-section.photo.content3")}
                <br />
                {t("menu-section.photo.content4")}
                <br />
                {t("menu-section.photo.content5")}
                <br />
                {t("menu-section.photo.content6")}
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={albumLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={albumMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={albumSmall} media="(max-width: 600px)" />
                  <Image src={albumLarge} alt="album" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.comment.title1")} <br />
                {t("menu-section.comment.title2")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.comment.content1")} <br />
                {t("menu-section.comment.content2")}
                <br />
                {t("menu-section.comment.content3")}
                <br />
                {t("menu-section.comment.content4")}
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={commentLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={commentMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={commentSmall} media="(max-width: 600px)" />
                  <Image src={commentLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.digital.title1")}
                <br />
                {t("menu-section.digital.title2")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.digital.content1")}
                <br />
                {t("menu-section.digital.content2")}
                <br />
                {t("menu-section.digital.content3")} <br />
                {t("menu-section.digital.content4")} <br />
                {t("menu-section.digital.content5")} <br />
                {t("menu-section.digital.content6")} <br />
                {t("menu-section.digital.content7")}
              </SectionSummary>
            </div>

            <div>
              <div>
                <ImageWrapper>
                  <picture>
                    <source srcSet={documentLarge} media="(min-width: 901px)" />
                    <source
                      srcSet={documentMedium}
                      media="(min-width: 601px) and (max-width: 900px)"
                    />
                    <source srcSet={documentSmall} media="(max-width: 600px)" />
                    <Image src={documentLarge} alt="comments" />
                  </picture>
                </ImageWrapper>
              </div>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.inspect.title1")}
                <br />
                {t("menu-section.inspect.title2")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.inspect.content1")} <br />
                {t("menu-section.inspect.content2")} <br />
                {t("menu-section.inspect.content3")} <br />
                {t("menu-section.inspect.content4")} <br />
                {t("menu-section.inspect.content5")} <br />
                {t("menu-section.inspect.content6")}
              </SectionSummary>
            </div>

            <div>
              <div>
                <ImageWrapper>
                  <picture>
                    <source
                      srcSet={inspectionLarge}
                      media="(min-width: 901px)"
                    />
                    <source
                      srcSet={inspectionMedium}
                      media="(min-width: 601px) and (max-width: 900px)"
                    />
                    <source
                      srcSet={inspectionSmall}
                      media="(max-width: 600px)"
                    />
                    <Image src={inspectionLarge} alt="inspection" />
                  </picture>
                </ImageWrapper>
              </div>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.drive.title1")}
                <br />
                {t("menu-section.drive.title2")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.drive.content1")} <br />
                {t("menu-section.drive.content2")}
                <br />
                {t("menu-section.drive.content3")} <br />
                {t("menu-section.drive.content4")} <br />
                {t("menu-section.drive.content5")} <br />
                {t("menu-section.drive.content6")}
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={driveLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={driveMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={driveSmall} media="(max-width: 600px)" />
                  <Image src={driveLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.material.title1")} <br />
                {t("menu-section.material.title2")} <br />
                {t("menu-section.material.title3")}
              </SectionTitle>
              <SectionSummary>
                {t("menu-section.material.content1")}
                <br />
                {t("menu-section.material.content2")}
                <br />
                {t("menu-section.material.content3")} <br />
                {t("menu-section.material.content4")} <br />
                {t("menu-section.material.content5")}
                <br />
                {t("menu-section.material.content6")} <br />
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={materialLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={materialMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={materialSmall} media="(max-width: 600px)" />
                  <Image src={materialLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                {t("menu-section.punchList.title1")} <br />
                {t("menu-section.punchList.title2")}
              </SectionTitle>

              <SectionSummary>
                {t("menu-section.punchList.content1")}
                <br />
                {t("menu-section.punchList.content2")}
                <br />
                {t("menu-section.punchList.content3")} <br />
                {t("menu-section.punchList.content4")} <br />
                {t("menu-section.punchList.content5")} <br />
                {t("menu-section.punchList.content6")} <br />
                {t("menu-section.punchList.content7")}
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={punchLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={punchMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={materialSmall} media="(max-width: 600px)" />
                  <Image src={punchSmall} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>
        </SliderWrapper>
      </MainContents>
    </Wrapper>
  );
};

export default ServiceMainContents;
